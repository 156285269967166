import React from 'react';
import useCountDown from 'react-countdown-hook';

const initialTime = 46 * 1000;
const interval = 1000;

const Timer = (props) => {
    const [timeLeft, { start }] = useCountDown(initialTime, interval);

    React.useEffect(() => {
        start();
    }, [start]);

    React.useEffect(() => {
        if (timeLeft === 1000) {
            props.next()
        }
    }, [timeLeft, props]);

    return (<>{(timeLeft/1000) - 1}</>);
}

export default Timer;
