import React from "react";
//import Snowfall from "react-snowfall";
import shortid from "shortid";

class Bars extends React.Component {
    getRandomKey() {
        return shortid.generate();
    }

    getCorrect() {
        let answers = this.props.question.answers.split(",")
        let correct = this.props.question.correct.split(",")
        if (parseInt(this.props.question.correct[0]) === 5) {
            return ["Fake"]
        } else {
            return correct.map(x => answers[parseInt(x)-1])
        }
    }

    render() {
        return (
            <div className="host-container">
                {/*<Snowfall/>*/}
                <div className="top-row">
                    <div className="answer-box" key={this.getRandomKey()}>
                        <div className="header">answers</div>
                            <div className="answer">
                                {this.getCorrect().map((item) => (
                                    <>
                                        • {item}
                                    </>
                                ))}
                            </div>
                    </div>
                    <div className="alert-box" key={this.getRandomKey()}>
                        <div className="header">alert</div>
                        <div className="alert">{this.props.question.alert}</div>
                    </div>
                    <div className="widget-box" key={this.getRandomKey()}>
                        <div className="header">next</div>
                        <div className="widget" onClick={this.props.next}>next</div>
                    </div>
                </div>

                <div className="bar-box" key={this.getRandomKey()}>
                    <div className="header">answers</div>
                    <div className="bars">
                        <div className="bar red" style={{height: this.props.red*50+50+"px"}}><div className="text">{this.props.red}</div></div>
                        <div className="bar blue" style={{height: this.props.blue*50+50+"px"}}><div className="text">{this.props.blue}</div></div>
                        <div className="bar green" style={{height: this.props.green*50+50+"px"}}><div className="text">{this.props.green}</div></div>
                        <div className="bar yellow" style={{height: this.props.yellow*50+50+"px"}}><div className="text">{this.props.yellow}</div></div>
                        <div className="bar joker" style={{height: this.props.pink*50+50+"px"}}><div className="text">{this.props.pink}</div></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Bars;