import React from "react";

class Finished extends React.Component {
    render() {
        return (
            <>
                <div className="host-container">
                    <div className="top-bar">
                        <div>NAME - {this.props.user}</div>
                        <div>QUESTION - {this.props.data.currentQuestion} / {this.props.data.totalQuestions}</div>
                        <div>ROOM - {this.props.code}</div>
                    </div>
                    <div className="localFont">
                        <div>Score - {this.props.results.score}</div>
                        <div>Minus - {this.props.results.minus}</div>
                        <div>First to Answer - {this.props.results.first}</div>
                        <div>Last to Answer - {this.props.results.last}</div>
                        <div>Correct - {this.props.results.correct}</div>
                        <div>Wrong - {this.props.results.wrong}</div>
                        <div>Streak - {this.props.results.streak} (WIP)</div>
                        <div>Traps - {this.props.results.traps}</div>
                    </div>
                </div>
            </>
        );
    }
}

export default Finished;