import React, {useEffect, useRef, useState} from "react";
import ReactCanvasConfetti from "react-canvas-confetti";
import Title from "../Comps/Title";

function Bingo() {
    const [items] = useState([
        {id: 0, selected: false, bingo: false, title: '"I\'m Tired"'},
        {id: 1, selected: false, bingo: false, title: '"Pill Time"'},
        {id: 2, selected: false, bingo: false, title: 'Maia Reveals Secret'},
        {id: 3, selected: false, bingo: false, title: 'Something Sexual'},
        {id: 4, selected: false, bingo: false, title: '"Oliver Is So Cute"'},
        {id: 5, selected: false, bingo: false, title: '"Can You Hear x"'},
        {id: 6, selected: false, bingo: false, title: '"I\'m Scared"'},
        {id: 7, selected: false, bingo: false, title: '"I\'m Sweaty"'},
        {id: 8, selected: false, bingo: false, title: 'Moans / Wining'},
        {id: 9, selected: false, bingo: false, title: 'Crying'},
        {id: 10, selected: false, bingo: false, title: '"Cute Little"'},
        {id: 11, selected: false, bingo: false, title: '"Mean"'},
        {id: 12, selected: false, bingo: false, title: '"Rude"'},
        {id: 13, selected: false, bingo: false, title: '"I\'m Getting Bullied"'},
        {id: 14, selected: false, bingo: false, title: 'Asking Ollie To Help'},
        {id: 15, selected: false, bingo: false, title: 'I Don\'t Say/do That'},
        {id: 16, selected: false, bingo: false, title: 'Check Messenger'},
        {id: 17, selected: false, bingo: false, title: '"You Guys Are Weird"'},
        {id: 18, selected: false, bingo: false, title: '"Pee Pee Poo Poo"'},
        {id: 19, selected: false, bingo: false, title: 'Asking Ollie To Translate'},
        {id: 20, selected: false, bingo: false, title: 'Sending link to cloths'},
        {id: 21, selected: false, bingo: false, title: 'Sending link to food'},
        {id: 22, selected: false, bingo: false, title: '"My x Hurts"'},
        {id: 23, selected: false, bingo: false, title: 'Speaks Danish'},
        {id: 24, selected: true, bingo: false, title: 'FREE TILE'},
        {id: 25, selected: false, bingo: false, title: '"I hate you"'},
        {id: 26, selected: false, bingo: false, title: '"It\'s not funny"'},
        {id: 27, selected: false, bingo: false, title: 'Tea'},
        {id: 28, selected: false, bingo: false, title: '"That Boy"'},
        {id: 29, selected: false, bingo: false, title: 'Maia Singing'},
        {id: 30, selected: false, bingo: false, title: 'Missing Ollie'},
        {id: 31, selected: false, bingo: false, title: 'Asking something medical'},
        {id: 32, selected: false, bingo: false, title: 'Hasn\'t eaten anything'},
        {id: 33, selected: false, bingo: false, title: 'Threatening Legal Action'},
        {id: 34, selected: false, bingo: false, title: 'Threatening to call cops'},
        {id: 35, selected: false, bingo: false, title: 'Mocking Bird'},
        {id: 36, selected: false, bingo: false, title: '"Da Bois"'},
        {id: 37, selected: false, bingo: false, title: 'British Accent'},
        {id: 38, selected: false, bingo: false, title: 'Something About Moving'},
        {id: 39, selected: false, bingo: false, title: 'Complaining about the bingo'},
        {id: 40, selected: false, bingo: false, title: 'Wawa my vagina'},
        {id: 41, selected: false, bingo: false, title: 'Maias Bored'},
        {id: 42, selected: false, bingo: false, title: 'Should I play x or y'},
        {id: 43, selected: false, bingo: false, title: 'Maia got a free item'},
    ])
    const [selectedItems, setSelectedItems] = useState(null)
    const [lastBingo, setLastBingo] = useState([{temp:"hi"}])
    const confettiRef = useRef(null);

    const shuffleArray = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }

    const onInit = ({ confetti }) => {
        confettiRef.current = confetti
    }

    const fire = () => {
        if (confettiRef.current) {
            confettiRef.current({
                startVelocity: 45,
                decay: 0.92,
                scalar: 1.2,
                spread: 180,
                ticks: 90,
                zIndex: 0,
                particleCount: 300,
                origin: {
                    x: 0.5,
                    y: 1.0
                }
            })
        }
    }

    const handleClick = (itemId) => {
        const updatedItems = selectedItems.map(item =>
            item.id === itemId ? { ...item, selected: !item.selected } : item
        )

        // Rest Bingo's
        updatedItems.forEach(item => {
            item.bingo = false;
        })

        // Check for bingo in rows
        for (let i = 0; i < 25; i += 5) {
            let row = updatedItems.slice(i, i + 5);
            if (row.every(item => item.selected)) {
                row.forEach(item => {
                    item.bingo = true;
                })
                if (!lastBingo.includes(row[0])) {
                    setLastBingo([...lastBingo, row[0]])
                    fire()
                }
            }
        }

        // Check for bingo in columns
        for (let i = 0; i < 5; i++) {
            let column = updatedItems.filter((_, index) => index % 5 === i);
            if (column.every(item => item.selected)) {
                column.forEach(item => {
                    item.bingo = true;
                })
                if (!lastBingo.includes(column[0])) {
                    setLastBingo([...lastBingo, column[0]])
                    fire()
                }
            }
        }

        // Check for bingo in the main diagonal
        let mainDiagonal = updatedItems.filter((_, index) => index % 6 === 0);
        console.log("main diag", mainDiagonal)
        if (mainDiagonal.every(item => item.selected)) {
            mainDiagonal.forEach(item => {
                item.bingo = true;
            })
            if (!lastBingo.includes(mainDiagonal[0])) {
                setLastBingo([...lastBingo, mainDiagonal[0]])
                fire()
            }
        }


        // Check for bingo in the anti-diagonal
        let antiDiagonal = updatedItems.filter((item, index) => [4, 8, 12, 16, 20].includes(index));
        console.log("anti diag", antiDiagonal)
        if (antiDiagonal.every(item => item.selected)) {
            antiDiagonal.forEach(item => {
                item.bingo = true;
            })
            if (!lastBingo.includes(antiDiagonal[0])) {
                setLastBingo([...lastBingo, antiDiagonal[0]])
                fire()
            }
        }

        setSelectedItems(updatedItems)
    }

    const getClass = (file) => {
        if (file.bingo) {
            return "flex justify-center items-center w-full h-full overflow-hidden rounded-lg bg-red-500 transition ease-in-out duration-300"
        }
        if (file.selected) {
            return "flex justify-center items-center w-full h-full overflow-hidden rounded-lg bg-blue-500 transition ease-in-out duration-300"
        }
        return "flex justify-center items-center w-full h-full overflow-hidden rounded-lg bg-gray-100 transition ease-in-out duration-300"
    }

    useEffect(() => {
        let shuffled = shuffleArray([...items])
        setSelectedItems(shuffled.slice(0, 25))
    }, [items])

    return (
        <>
            <Title title={"Maia Bingo"} desc={"A game designed to show Maia how unique her dialogue is."} />
            <ul className="grid grid-cols-5 grid-rows-5 gap-4 flex-1">
                {!!selectedItems && (
                    <>
                        {selectedItems.map((file) => (
                            <li key={file.id} className="relative cursor-pointer" onClick={() => handleClick(file.id)}>
                                <div className={getClass(file)}>
                                    <p className={file.selected ? "text-l font-medium text-black transition ease-in-out duration-300" : "text-l font-medium text-gray-500 transition ease-in-out duration-300"}>{file.title}</p>
                                </div>
                            </li>
                        ))}
                    </>
                )}
            </ul>
            <ReactCanvasConfetti
                onInit={onInit}
            />
        </>
    );
}

export default Bingo;
