import { BehaviorSubject } from 'rxjs';

import { handleResponse } from './handle-response';

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

export const authenticationService = {
    login,
    logout,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue () { return currentUserSubject.value }
};

function login(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    };

    console.log(process.env.REACT_APP_API_ROUTES)

    return fetch(`${process.env.REACT_APP_API_ROUTES}/user/login`, requestOptions)
        .then(handleResponse)
        .then(user => {
           localStorage.setItem('currentUser', JSON.stringify(user));
            currentUserSubject.next(user);

            return user;
        });
}

function logout(user) {
    var requestOptions = null
    try {
        requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({"token": user.token})
        }
    } catch (e) {
        requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
        }
    }

    fetch(`${process.env.REACT_APP_API_ROUTES}/user/logout`, requestOptions)
        .then(res => res.text())
        .catch(err => err);

    localStorage.removeItem('currentUser');
    currentUserSubject.next(null);
}
