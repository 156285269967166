import React from "react";
//import Snowfall from "react-snowfall";
import CountUp from "react-countup";
import FlipMove from "react-flip-move";

class Stats extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            players: this.props.players
        }
    }

    sortList = () => {
        let temp = this.state.players.sort((a, b) => b.score - a.score);
        this.setState({players: temp})
    }

    componentDidMount() {
        setTimeout(this.sortList, 3000)
    }

    render() {
        const {players} = this.state
        let streak = players.filter(o => o.streak === Math.max(...players.map(o => o.streak)));
        let correct = players.filter(o => o.correct === Math.max(...players.map(o => o.correct)));
        let firstN = players.filter(o => o.first === Math.max(...players.map(o => o.first)));
        let lastN = players.filter(o => o.last === Math.max(...players.map(o => o.last)));
        let minus = players.filter(o => o.minus === Math.max(...players.map(o => o.minus)));
        let wrong = players.filter(o => o.wrong === Math.max(...players.map(o => o.wrong)));
        return (
            <div className="host-container">
                {/*<Snowfall/>*/}
                <div className="score-row">
                    <div className="score-box">
                        <div className="header">scores</div>
                        <div className="scores">
                            <FlipMove
                                duration={500}
                                typeName="ul"
                                enterAnimation="accordianVertical"
                                leaveAnimation="accordianVertical"
                            >
                            {players.map((item) => (
                                <div className="score" key={item.username}>
                                    <span style={{marginLeft: '10px', width: '110px', maxWidth: '110px', textAlign:'start'}}>{players.indexOf(item)+1}.</span>
                                    <span style={{flex: '1'}}>{item.username}</span>
                                    <span style={{width: '120px', maxWidth: '120px', textAlign: 'end'}}><CountUp end={item.score} delay={0.5} duration={1} start={item.previousScore} /></span>
                                </div>
                            ))}
                            </FlipMove>
                        </div>
                    </div>
                    <div className="score-box">
                        <div className="header">header</div>
                        <div className="scores">
                            <div className="score">Best Streak</div>
                            {streak.slice(0,3).map((item) => (
                                <div className="score2">{item.username} - {item.streak}</div>
                            ))}
                            <div className="score">Most Correct</div>
                            {correct.slice(0,3).map((item) => (
                                <div className="score2">{item.username} - {item.correct}</div>
                            ))}
                            <div className="score">Fastest Hand</div>
                            {firstN.slice(0,3).map((item) => (
                                <div className="score2">{item.username} - {item.first}</div>
                            ))}
                            <div className="score">Slowest Hand</div>
                            {lastN.slice(0,3).map((item) => (
                                <div className="score2">{item.username} - {item.last}</div>
                            ))}
                            <div className="score">Most Traps Triggered</div>
                            {minus.slice(0,3).map((item) => (
                                <div className="score2">{item.username} - {item.minus}</div>
                            ))}
                            <div className="score">Dissapointing Performance</div>
                            {wrong.slice(0,3).map((item) => (
                                <div className="score2">{item.username} - {item.wrong}</div>
                            ))}
                        </div>
                    </div>
                    <div className="widget-box">
                        <div className="header">next</div>
                        <div className="widget" onClick={this.props.next}>next</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Stats;