import React, {useEffect, useState} from "react";
import {authenticationService} from "../Scripts/authentication.service";
import {handleResponse} from "../Scripts/handle-response";
import {Link} from "react-router-dom";
import Loading from "../Loading/Loading";
import {UserIcon, UserPlusIcon} from "@heroicons/react/16/solid";
import Title from "../Comps/Title";

const View = () => {
    const [users, setUsers] = useState([])
    const [loaded, setLoaded] = useState(false)

    const getGames = () => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
        };

        fetch(`${process.env.REACT_APP_API_ROUTES}/profile/getUsers`, requestOptions)
            .then(handleResponse)
            .then((result) => {
                    setUsers(result)
                    setLoaded(true)
                },
                (error) => {console.error(error)}
            )
    }

    useEffect(() => {
        getGames()
    }, [])

    if (loaded) {
        return (
            <>
                <Title title={"Users"} desc={"Idk see users or sumin."} />
                <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                    {users.map((user) => (
                        <li key={user.id} className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
                            <div className="flex w-full items-center justify-between space-x-6 p-6">
                                <div className="flex-1 truncate">
                                    <div className="flex items-center space-x-3">
                                        <h3 className="truncate text-sm font-medium text-gray-900">{user.username}</h3>
                                        {!!user.admin && (
                                            <span className="inline-flex flex-shrink-0 items-center rounded-full bg-red-400 px-1.5 py-0.5 text-xs font-medium text-red-950 ring-1 ring-inset ring-red-800/20">
                                                  Admin
                                                </span>
                                        )}
                                        {!!user.host && (
                                            <span className="inline-flex flex-shrink-0 items-center rounded-full bg-blue-300 px-1.5 py-0.5 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-600/20">
                                                  Host
                                                </span>
                                        )}
                                        {!!user.create && (
                                            <span className="inline-flex flex-shrink-0 items-center rounded-full bg-green-50 px-1.5 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                                  Creator
                                                </span>
                                        )}
                                    </div>
                                    <p className="mt-1 truncate text-sm text-gray-500">Hours Alive: {((new Date() - new Date(user.date_created)) / (1000 * 60 * 60)).toFixed(2)}</p>
                                </div>
                                <img className="h-10 w-10 flex-shrink-0 rounded-full bg-gray-300 object-cover" src={user.pic} alt="" />
                            </div>
                            <div>
                                <div className="-mt-px flex divide-x divide-gray-200">
                                    <div className="flex w-0 flex-1">
                                        <Link className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900" to={`/profile/`+user.username}>
                                            <UserIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                            View
                                        </Link>
                                    </div>
                                    <div className="-ml-px flex w-0 flex-1">
                                        <div className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900">
                                            <UserPlusIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                            Add
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </>
        );
    } else {
        return <Loading />
    }
};

export default View;
