import React from "react";
import {authenticationService} from "../Scripts/authentication.service";
import {handleResponse} from "../Scripts/handle-response";

import Card from "./components/card";
import Loading from "../Loading/Loading";

class Cards extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            cards: []
        }
    }

    getCards() {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
        };

        fetch(`${process.env.REACT_APP_API_ROUTES}/card/getCards`, requestOptions)
            .then(handleResponse)
            .then((result) => {
                    this.setState({
                        cards: result,
                        isLoaded: true,
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    componentDidMount() {
        this.getCards()
    }

    render() {
        const {cards, isLoaded} = this.state
        if (isLoaded) {
            return (
                <>
                    <div style={{alignItems: "center", justifyContent: "center", fontSize: "50px", height: "100%", display: "flex", flexDirection: "column"}}>
                        <div className="cards">
                            {cards.map((item) => (<Card src={item.src} alt={item.title} />))}
                        </div>
                    </div>
                </>
            );
        } else {
            return <Loading />
        }
    }
}

export default Cards;