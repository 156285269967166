import React from "react";
//import Snowfall from "react-snowfall";

class PreQuestion extends React.Component {
    componentDidMount() {
        setTimeout(this.props.next, 5000)
    }

    render() {
        return (
            <div className="host-container">
                {/*<Snowfall/>*/}
                <div className="pre-question-box">
                    <div className="header">question</div>
                    <div className="text">
                        {this.props.question.question}
                    </div>
                </div>
            </div>
        );
    }
}

export default PreQuestion;