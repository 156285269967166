import React from "react";

class PreAnswer extends React.Component {
    render() {
        console.log(this.props)
        return (
            <>
                <div className="host-container">
                    <div className="top-bar">
                        <div>NAME - {this.props.user}</div>
                        <div>QUESTION - {this.props.data.currentQuestion} / {this.props.data.totalQuestions}</div>
                        <div>ROOM - {this.props.code}</div>
                    </div>
                    <div className="localFont">
                        {this.props.data.question}
                    </div>
                </div>
            </>
        );
    }
}

export default PreAnswer;
