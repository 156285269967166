import React from "react";
import {LinearProgress} from "@mui/material";

class Home extends React.Component {
    render() {
        return (
            <>
                <div className="wip">
                    <LinearProgress style={{width:"70%",height:"10px"}} color="secondary" />
                </div>
            </>
        );
    }
}

export default Home;