import React from "react";
//import Snowfall from "react-snowfall";

class Menu extends React.Component {
    render() {
        return (
            <div className="host-container">
                {/*<Snowfall/>*/}
                <div className="top-host">
                    <div className="logo">KASHOOT.CO.UK</div>
                    <div className="roomCode">ROOM - {this.props.code}</div>
                </div>
                <div className="host-box">
                    <div style={{display:'flex', flexWrap:'wrap', height:'85%', padding:'0 50px'}}>
                        {this.props.players.map((user, index) => (
                            <div key={index} style={{marginBottom: '1em', fontWeight:'600', height:'40px', marginRight:'20px'}}>
                                <button className="field-button menu-jump">{user.username}</button>
                            </div>
                        ))}
                    </div>
                    <button className="field-button" onClick={ () => this.props.next()}>START</button>
                </div>
            </div>
        );
    }
}

export default Menu;
