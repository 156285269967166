import React from "react";

class Answer extends React.Component {
    render() {
        return (
            <>
                <div className="host-container">
                    <div className="top-bar">
                        <div>NAME - {this.props.user}</div>
                        <div>QUESTION - {this.props.data.currentQuestion} / {this.props.data.totalQuestions}</div>
                        <div>ROOM - {this.props.code}</div>
                    </div>
                    <div style={{display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", height: "100%"}}>
                        <div style={{display: "flex"}}>
                            <button onClick={ () => this.props.answer(1)} className="display-answer red" />
                            <button onClick={ () => this.props.answer(2)} className="display-answer blue" />
                        </div>
                        <div style={{display: "flex"}}>
                            <button onClick={ () => this.props.answer(3)} className="display-answer green" />
                            <button onClick={ () => this.props.answer(4)} className="display-answer yellow" />
                        </div>
                        <div className="display-answer joker" style={{height: "100px", width: "320px"}} onClick={ () => this.props.answer(5)}>
                            <span>Joker</span>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Answer;