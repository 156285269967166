import React, {useState} from "react";
import {authenticationService} from "../../Scripts/authentication.service";
import TokenIcon from "@mui/icons-material/Token";
import Button from "@mui/material/Button";
import {handleResponse} from "../../Scripts/handle-response";
import {useSnackbar} from "notistack";

const Upgrades2 = () => {
    const [user] = useState(authenticationService.currentUserValue)
    const { enqueueSnackbar } = useSnackbar()

    const unlockHost = () => {
        enqueueSnackbar('Confirming With Server', {variant:"info"})
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
            body: JSON.stringify({'user': user})
        };

        fetch(`${process.env.REACT_APP_API_ROUTES}/store/unlockHost`, requestOptions)
            .then(handleResponse)
            .then((result) => {
                    enqueueSnackbar('Purchase Success', {variant:"success"})
                    let user = authenticationService.currentUserValue
                    user.host = result.host
                    user.create = result.create
                    user.mealdealz = result.mealdealz
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    this.props.refresh()
                }
            )
    }

    const unlockCreate = () => {
        enqueueSnackbar('Confirming With Server', {variant:"info"})
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
            body: JSON.stringify({'user': user})
        };

        fetch(`${process.env.REACT_APP_API_ROUTES}/store/unlockCreate`, requestOptions)
            .then(handleResponse)
            .then((result) => {
                    enqueueSnackbar('Purchase Success', {variant:"success"})
                    let user = authenticationService.currentUserValue
                    user.host = result.host
                    user.create = result.create
                    user.mealdealz = result.mealdealz
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    this.props.refresh()
                }
            )
    }

    return (
        <>
            <div className="item">
                <div className="title">Hoster</div>
                <div className="amount">
                    <TokenIcon color="secondary" />
                    <div style={{marginLeft:"5px"}}>5</div>
                </div>
                <Button disabled={user.host || user.mealdealz < 5} variant="contained" color="secondary" onClick={ () => unlockHost()}>Acquire</Button>
            </div>
            <div className="item">
                <div className="title">Creator</div>
                <div className="amount">
                    <TokenIcon color="secondary" />
                    <div style={{marginLeft:"5px"}}>5</div>
                </div>
                <Button disabled={user.create  || user.mealdealz < 5} variant="contained" color="secondary" onClick={ () => unlockCreate()}>Acquire</Button>
            </div>
            <div className="item">
                <div className="title">Admin</div>
                <div className="amount">
                    <TokenIcon color="secondary" />
                    <div style={{marginLeft:"5px"}}>100</div>
                </div>
                <Button disabled={user.admin  || user.mealdealz < 100} variant="contained" color="secondary" onClick={ () => alert("WIP")}>Acquire</Button>
            </div>
        </>
    );
};

export default Upgrades2;
