import React from "react";
//import Snowfall from "react-snowfall";
import Timer from "./timer";

class Question extends React.Component {
    render() {
        return (
            <div className="host-container">
                {/*<Snowfall/>*/}
                <div className="top-row">
                    <div className="content-box">
                        <div className="header">
                            content
                        </div>
                        <div className="container">
                            <img src={this.props.question.img} alt="question" />
                        </div>
                    </div>
                    <div className="question-box">
                        <div className="header">question</div>
                        <div className="text">
                            {this.props.question.question}
                        </div>
                    </div>
                    <div className="widget-box">
                        <div className="header">widgets</div>
                        <div className="widget">
                        <Timer next={ this.props.next } />
                        </div>
                        <div className="widget">ANSWERED - {this.props.answered} / {this.props.players.length}</div>
                        <div className="widget">QUESTION - {this.props.data.currentQuestion} / {this.props.data.totalQuestions}</div>
                        <div className="widget">TOPDOG - {this.props.players[0].username}</div>
                        <div className="widget">SHITZOO - {this.props.players.at(-1).username}</div>
                        <div className="widget" onClick={ () => this.props.next() }>skip</div>
                    </div>
                </div>
                <div className="option-box">
                    <div className="header">options</div>
                    <div className="options">
                        <div className="option red"><span>{this.props.question.answers.split(",")[0]}</span></div>
                        <div className="option blue"><span>{this.props.question.answers.split(",")[1]}</span></div>
                        <div className="option green"><span>{this.props.question.answers.split(",")[2]}</span></div>
                        <div className="option yellow"><span>{this.props.question.answers.split(",")[3]}</span></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Question;