import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import Upgrades from "./componenets/Upgrades";
import WIP from "../Errors/wip";

const Store2 = () => {
    const [tab, setTab] = useState(0)
    const navigate = useNavigate()

    const refresh = () => {
        navigate(0)
    }

    return (
        <div className="store">
            <div className="tabs">
                <div className={tab === 0 ? "tab active" : "tab"} onClick={() => setTab(0)}>Upgrades</div>
                <div className={tab === 1 ? "tab active" : "tab"} onClick={() => setTab(1)}>Titles</div>
                <div className={tab === 2 ? "tab active" : "tab"} onClick={() => setTab(2)}>Frames</div>
                <div className={tab === 3 ? "tab active" : "tab"} onClick={() => setTab(3)}>Taunts</div>
                <div className={tab === 4 ? "tab active" : "tab"} onClick={() => setTab(4)}>Themes</div>
            </div>
            <div className="items">
                {tab === 0 ? <Upgrades refresh={refresh} /> : <WIP />}
            </div>
        </div>
    );
};

export default Store2;
