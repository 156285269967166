import React from "react";
import shortid from "shortid";
//import Snowfall from "react-snowfall";

class Finished extends React.Component {
    getRandomKey() {
        return shortid.generate();
    }

    render() {
        return (
            <div className="host-container">
                {/*<Snowfall/>*/}
                <div className="score-box" style={{margin:"10px"}} key={this.getRandomKey()}>
                    <div className="header">final scores</div>
                    <div className="scores">
                        {this.props.players.map((item) => (
                            <div className="score" onClick={ () => this.kick(item.username)} key={item.username}>
                                <span style={{marginLeft: '10px', width: '110px', maxWidth: '110px', textAlign:'start'}}>{this.props.players.indexOf(item)+1}.</span>
                                <span style={{flex: '1'}}>{item.username}</span>
                                <span style={{width: '120px', maxWidth: '120px', textAlign: 'end'}}>{item.score}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}

export default Finished;