import React from "react";
import countdownMusic from '../Music/self-destruct-alarm-count.mp3'

var cdMusic = new Audio(countdownMusic);
cdMusic.volume = 0.05;

class Admin extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            alarm: false,
            countdown: 9,
            off: false,
            detonate: false,
        }
    }

    glass = () => {
        if (this.state.open) {
            this.setState({
                open: false
            })
        } else {
            this.setState({
                open: true
            })
        }
    }

    detonate = () => {
        if (this.state.detonate) {
            this.setState({
                detonate: false
            })
        } else {
            this.setState({
                detonate: true
            })
        }
    }

    off = () => {
        if (this.state.off) {
            this.setState({
                off: false
            })
        } else {
            this.setState({
                off: true
            })
        }
    }

    alarm = () => {
        if (this.state.alarm) {
            cdMusic.pause()
            cdMusic.currentTime = 0
            this.setState({
                alarm: false
            })
        } else {
            this.setState({
                alarm: true,
                countdown: 9
            }, () => {
                this.countdown()
            })
        }
    }

    countdown = () => {
        cdMusic.play()
        let count = setInterval(() => {
            let time = this.state.countdown
            if(time - 1 === 1) {
                this.detonate()
            }
            if(time - 1 < 0) {
                clearInterval(count)
                this.off()
            } else {
                time--
                this.setState({countdown:time})
            }
        }, 1000)
    }

    componentDidMount() {
        document.body.style.overflow = "hidden"
    }

    componentWillUnmount() {
        document.body.style.overflow = "unset"
    }

    render() {
        const {open, alarm, countdown, off, detonate} = this.state
        return (
            <>
                <div className="admin">
                    <div className="warning"></div>

                    <div className="base">
                        <button onClick={this.alarm} id="activate">
                            <span></span>
                        </button>
                    </div>

                    <div onClick={this.glass} className={open ? "box opened" : "box"} id="cover">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <span></span><span></span>
                    </div>

                    <div className="hinges"></div>

                    <div className="text">
                        SELF-&thinsp;DESTRUCT
                    </div>

                    <div id="panel" className={alarm ? "show" : ""}>
                        <div id="msg">DEVICE SELF-DESTRUCTION</div>
                        <div id="time">{countdown}</div>
                        <span id="abort" className={detonate ? "hide" : ""} onClick={this.alarm}>ABORT</span>
                        <span id="detonate" className={detonate ? "show" : ""}>DETONATE</span>
                    </div>

                    <div id="turn-off" className={off ? "close" : ""}></div>
                    <div id="closing" className={off ? "close" : ""}></div>
                </div>
            </>
        );
    }
}

export default Admin;