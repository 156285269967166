import React from "react";
import {Link} from "react-router-dom";

function Home() {
    return (
        <div className="bg-white">
            <div className="mx-auto h-dvh max-w-7xl sm:px-6 lg:px-8 flex items-center">
                <div className="mx-auto max-w-2xl text-center">
                    <h2 className="font-bold tracking-tight text-gray-900 text-6xl">Welcome To<br/>Kashoot</h2>
                    <p className="mx-auto mt-6 max-w-xl text-3xl leading-8 text-gray-600">Here we want to kashoot our selves</p>
                    <div className="mt-10 flex items-center justify-center gap-x-6">
                        <Link to="/login"><div className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-l font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Login</div></Link>
                        <Link to="/newUser"><div className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-l font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Sign Up</div></Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;
