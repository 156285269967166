import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {authenticationService} from "../Scripts/authentication.service";
//import Alert from "@mui/material/Alert";
//import AlertTitle from "@mui/material/AlertTitle";
//import TextField from "@mui/material/TextField";
//import InputAdornment from "@mui/material/InputAdornment";
//import Person from "@mui/icons-material/Person";
//import Lock from "@mui/icons-material/Lock";
//import Button from "@mui/material/Button";
import { ExclamationCircleIcon } from '@heroicons/react/20/solid'

function Login() {
    const [user, setUser] = useState('')
    const [pass, setPass] = useState('')
    const [error, setError] = useState(false)
    const [submit, setSubmit] = useState(false)
    let navigate = useNavigate();

    useEffect(() => {
        if (submit) {
            authenticationService.login(user, pass)
                .then(
                    user => {
                        console.log("login", user)
                        navigate('/', {state: {login: true}})
                    },
                    error => {
                        console.log("no Login", error)
                        setSubmit(false)
                        setError(true)
                    }
                );
        }
    }, [submit, pass, user, navigate])

    const login = () => {
        setSubmit(true)
    }

    return (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <img
                    className="mx-auto h-40 w-auto"
                    src="https://i.imgur.com/uvlMpvK.png"
                    alt="Red Corp"
                />
                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                    Sign in to your account
                </h2>
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <div className="space-y-6">
                    <div>
                        <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                            Username
                        </label>
                        <div className="mt-2 relative ">
                            <input
                                id="username"
                                name="username"
                                type="username"
                                placeholder="User"
                                autoComplete="username"
                                required
                                onChange={e => setUser(e.target.value)}
                                className={error
                                    ? "block w-full rounded-md border-0 p-2 text-red-900 shadow-sm ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-2 focus:ring-inset focus:ring-red-500 sm:text-sm sm:leading-6"
                                    : "block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                }
                            />
                            {error && (
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                                </div>
                            )}
                        </div>
                        <p className="mt-2 text-sm text-red-600" id="email-error">
                            {error ? "Not a valid username." : "⠀"}
                        </p>
                    </div>

                    <div>
                        <div className="flex items-center justify-between">
                            <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                Password
                            </label>
                            {/* Forgot password not exist yet
                            <div className="text-sm">
                                <div href="#" className="font-semibold text-indigo-600 hover:text-indigo-500">
                                    Forgot password?
                                </div>
                            </div>*/}
                        </div>
                        <div className="relative mt-2">
                            <input
                                id="password"
                                name="password"
                                placeholder="Password"
                                type="password"
                                autoComplete="current-password"
                                required
                                onChange={e => setPass(e.target.value)}
                                className={error
                                    ? "block w-full rounded-md border-0 p-2 text-red-900 shadow-sm ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-2 focus:ring-inset focus:ring-red-500 sm:text-sm sm:leading-6"
                                    : "block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                }
                            />
                            {error && (
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                                </div>
                            )}
                        </div>
                        <p className="mt-2 text-sm text-red-600" id="email-error">
                            {error ? "Not a valid password." : "⠀"}
                        </p>

                    </div>

                    <div>
                        <button
                            type="submit"
                            disabled={submit}
                            onClick={login}
                            className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Sign in
                        </button>
                    </div>
                </div>

                <p className="mt-10 text-center text-sm text-gray-500">
                    Not a member?{' '}
                    <Link to="/newUser">
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
                            Sign up here.
                        </a>
                    </Link>

                </p>
            </div>
            {/* Old Login
        <div className="login">
            {error ? (
                <Alert style={{marginBottom: "20px"}} severity="error" variant="filled">
                    <AlertTitle>Error</AlertTitle>
                    Incorrect username or password
                </Alert>
            ) : ""}
            <div className="box">
                <TextField
                    error={error ? "error" : ""}
                    label="Username"
                    variant="outlined"
                    color="secondary"
                    focused
                    onChange={e => (setUser(e.target.value))}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Person color="secondary" />
                            </InputAdornment>
                        ),
                    }}/>
                <TextField
                    className="separator"
                    error={error ? "error" : ""}
                    type="password"
                    variant="outlined"
                    label="Password"
                    color="secondary"
                    focused
                    onChange={e => (setPass(e.target.value))}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Lock color="secondary" />
                            </InputAdornment>
                        ),
                    }}/>
                <Button className="separator" variant="contained" color="secondary" disabled={submit} onClick={login}>Login</Button>
                <Link to="/newUser"><Button className="separator" style={{width: "100%"}} disabled={submit} variant="contained" color="secondary">Create New User</Button></Link>
            </div>
            <div className={anim ? "anim" : "no-anim anim"}>
                <svg ref={svgRef} className="intro" viewBox="0 0 200 86">
                    <text textAnchor="start" x="10" y="30" className="text text-stroke text-undo"
                          clipPath="url(#text1)">Welcome
                    </text>
                    <text textAnchor="start" x="10" y="50" className="text text-stroke text-undo"
                          clipPath="url(#text2)">To
                    </text>
                    <text textAnchor="start" x="10" y="70" className="text text-stroke text-undo"
                          clipPath="url(#text3)">Kashoot 2.0
                    </text>
                    <text textAnchor="start" x="10" y="30" className="text text-stroke text-undo text-stroke-2"
                          clipPath="url(#text1)">Welcome
                    </text>
                    <text textAnchor="start" x="10" y="50" className="text text-stroke text-undo text-stroke-2"
                          clipPath="url(#text2)">To
                    </text>
                    <text textAnchor="start" x="10" y="70" className="text text-stroke text-undo text-stroke-2"
                          clipPath="url(#text3)">Kashoot 2.0
                    </text>
                    <defs>
                        <clipPath id="text1">
                            <text textAnchor="start" x="10" y="30" className="text">Welcome</text>
                        </clipPath>
                        <clipPath id="text2">
                            <text textAnchor="start" x="10" y="50" className="text">To</text>
                        </clipPath>
                        <clipPath id="text3">
                            <text textAnchor="start" x="10" y="70" className="text">Kashoot 2.0</text>
                        </clipPath>
                    </defs>
                </svg>
            </div>
        </div>*/}
        </div>
    );
}

export default Login;
