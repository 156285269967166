import React, {useEffect, useRef, useState} from "react";
import loop1 from "../Music/Lupus Nocte - Milky Way Express.mp3";
import loop2 from "../Music/Lupus Nocte - Hadouken.mp3";
import loop3 from "../Music/Lupus Nocte - Howling.mp3";
import io from "socket.io-client";
import {authenticationService} from "../Scripts/authentication.service";
import Menu from "./components/Menu";
import PreQuestion from "./components/Pre-Question";
import Question from "./components/Question";
import Bars from "./components/Bars";
import Stats from "./components/Stats";
import Finished from "./components/Finished";
import Loading from "../Loading/Loading";
import {handleResponse} from "../Scripts/handle-response";
import {useLocation} from "react-router-dom";
import shortid from "shortid";

let l1 = new Audio(loop1);
let l2 = new Audio(loop2);
let l3 = new Audio(loop3);
l1.loop = true;
l2.loop = true;
l3.loop = true;
l1.volume = 0.01;
l2.volume = 0.01;
l3.volume = 0.01;

const socket = io(process.env.REACT_APP_API);

const Host2 = () => {
    const [loaded, setLoaded] = useState(false)
    const [stage, setStage] = useState(0)
    const [next, setNext] = useState(0)
    const [data, setData] = useState({
        totalQuestions:1,
        currentQuestion:1,
        question:"Cheese?",
    })
    const [refresh, setRefresh] = useState(shortid.generate())

    const game = useRef([])
    const first = useRef(false);
    const answered = useRef(0);
    const players = useRef([])
    const startTime = useRef(null)
    const questions = useRef([])
    const question = useRef(0)

    const [red, setRed] = useState(0)
    const [blue, setBlue] = useState(0)
    const [green, setGreen] = useState(0)
    const [yellow, setYellow] = useState(0)
    const [pink, setPink] = useState(0)

    const location = useLocation();

    const getGame = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
            body: JSON.stringify({'id': location.state.id})
        };

        fetch(`${process.env.REACT_APP_API_ROUTES}/game/getGame`, requestOptions)
            .then(handleResponse)
            .then((result) => {
                    game.current = result[0]
                    console.log(result[0])
                    getQuestions()
                    let code = result[0].code
                    socket.emit('host', {code})
                    joinHandler()
                    answerHandler()
                },
                (error) => {console.error(error)}
            )
    }

    const getQuestions = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
            body: JSON.stringify({'id': location.state.quiz})
        };

        fetch(`${process.env.REACT_APP_API_ROUTES}/question/getQuestions`, requestOptions)
            .then(handleResponse)
            .then((result) => {
                    let d = data
                    d.totalQuestions = result.length
                    d.question = result[0].question
                    setData(d)
                    questions.current = result
                    setLoaded(true)
                },
                (error) => {console.error(error)}
            )
    }

    const joinHandler = () => {
        console.log("I Run first")
        socket.on('userJoin', ({user}) => {
            console.log("I Run")
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
                body: JSON.stringify({'user': user.id, 'code': game.current.code})
            };

            fetch(`${process.env.REACT_APP_API_ROUTES}/game/newGameUser`, requestOptions)
                .then(handleResponse)
                .then((result) => {
                        fetch(`${process.env.REACT_APP_API_ROUTES}/game/getGameUser`, requestOptions)
                            .then(handleResponse)
                            .then((result2) => {
                                let p = players.current;
                                let gp = result2[0]
                                gp.username = user.username
                                gp.previousScore = 0
                                gp.answered = 0
                                p.push(gp)
                                players.current = p
                                setRefresh(shortid.generate())
                                console.log(p)
                                console.log(players.current)
                            })
                    },
                    (error) => {console.error(error)}
                )
        })
    }

    const answerHandler = () => {
        socket.on('answer', ({user, answer}) => {
            addColor(answer)
            let q = questions.current
            let cq = question.current
            let p = players.current
            let c = p.filter(obj => {return obj.username === user.username})[0]
            let currentIndex = p.indexOf(c)
            let answerTime = new Date()
            let scoreMultiplier = 1000
            answered.current += 1
            if (!first.current) {
                first.current = true
                startTime.current = answerTime
                c.first ++
            } else {
                let seconds = Math.round((answerTime - startTime) / 1000)
                scoreMultiplier = Math.round(((45 - seconds) / 45) * 1000)
            }

            console.log("----------")
            console.log("ANSWER RECEIVED")
            console.log("USER:", user)
            console.log("ANSWER:", answer)
            console.log("TOTAL:", answered.current, "/", p.length)
            c.previousScore = c.score
            c.answered = 1

            if (answer === 5) {
                if (q[cq].correct.includes(answer)) {
                    c.score += 5000
                    c.correct ++
                    c.streak ++
                } else {
                    c.score -= 3000
                    c.streak = 0;
                    c.minus += 3000
                    c.wrong ++
                }
            } else {
                if (q[cq].correct.includes(answer)) {
                    c.score += 1 * scoreMultiplier;
                    c.correct ++
                    c.streak ++
                } else if (q[cq].trick.includes(answer)) {
                    c.score -= 1000
                    c.streak = 0
                    c.minus += 1000
                    c.wrong ++
                    c.traps ++
                } else {
                    c.streak = 0
                    c.wrong ++
                }
            }

            if (answered.current === p.length) {
                c.last ++
            }

            p[currentIndex] = c
            updateScores(c)
            players.current = p

            setRefresh(shortid.generate())

            if (answered.current === p.length) {
                checkAnswered()
            }
        })
    }

    const checkAnswered = () => {
        let p = players.current
        p.forEach((player, index) => {
            if (player.answered === 0) {
                player.score -= 1000
                player.streak = 0
                player.minus += 1000
                player.wrong ++
                player.traps ++
            } else {
                player.answered = 0
            }
            p[index] = player
            updateScores(player)
            setRefresh(shortid.generate())
        })

        players.current = p
        setNext(next + 1)
    }

    const addColor = (answer) => {
        switch (answer) {
            case 1:
                setRed(red + 1)
                break;
            case 2:
                setBlue(blue + 1)
                break;
            case 3:
                setGreen(green + 1)
                break;
            case 4:
                setYellow(yellow + 1)
                break;
            case 5:
                setPink(pink + 1)
                break;
            default:
                console.log("your mom gae")
        }
    }

    const updateScores = (player) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
            body: JSON.stringify({"user":player, "code":game.current.code})
        };

        fetch(`${process.env.REACT_APP_API_ROUTES}/game/updateGameUser`, requestOptions)
            .then(handleResponse)
            .then((result) => {console.log(result)}, (error) => {console.error(error)})
    }

    const depositScores = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
            body: JSON.stringify({"code":game.current.code})
        };

        fetch(`${process.env.REACT_APP_API_ROUTES}/game/addGameScore`, requestOptions)
            .then(handleResponse)
            .then((result) => {
                    console.log(result)
                },
                (error) => {
                    console.log(error)
                }
            )
    }

    const awardWinner = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
            body: JSON.stringify({"user":players.current[0], "quiz":game.current.quiz})
        };

        fetch(`${process.env.REACT_APP_API_ROUTES}/game/awardWin`, requestOptions)
            .then(handleResponse)
            .then((result) => {
                    console.log(result)
                },
                (error) => {
                    console.log(error)
                }
            )
    }

    const awardLosers = () => {
        let losers = players.current.slice(1,players.current.length)
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
            body: JSON.stringify({"users":losers})
        };

        fetch(`${process.env.REACT_APP_API_ROUTES}/game/awardLoss`, requestOptions)
            .then(handleResponse)
            .then((result) => {
                    console.log(result)
                },
                (error) => {
                    console.log(error)
                }
            )
    }

    const closeGame = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
            body: JSON.stringify({"code":game.current.code})
        };

        socket.emit('closed', {code:game.current.code})

        console.log("off")
        socket.removeAllListeners();

        fetch(`${process.env.REACT_APP_API_ROUTES}/game/closeGame`, requestOptions)
            .then(handleResponse)
            .then((result) => {console.log(result)}, (error) => {console.error(error)})
    }

    const handleNext = () => {
        setNext(next + 1)
    }

    const sortList = () => {
        let p = players.current.sort((a, b) => b.score - a.score);
        players.current = p
        setRefresh(shortid.generate())
    }

    useEffect(() => {
        if (!loaded) {return}
        let q = questions.current
        let cq = question.current
        if (q[cq]) {
            switch (stage) {
                default:
                    setStage(1)
                    break;
                case 1:
                    l1.pause();
                    l2.play();
                    l3.pause();
                    setStage(2)
                    break;
                case 2:
                    setStage(3)
                    break;
                case 3:
                    let d = data
                    if (q[cq+1]) {
                        d.currentQuestion = cq+2
                        d.question = q[cq+1].question
                    }
                    question.current += 1
                    setData(d)
                    startTime.current = null
                    answered.current = 0
                    first.current = false
                    setStage(4)
                    break;
                case 4:
                    setStage(1)
                    sortList()
                    setRed(0)
                    setBlue(0)
                    setGreen(0)
                    setYellow(0)
                    setPink(0)
                    break;
            }
        } else {
            l1.pause();
            l2.pause();
            l3.play();
            sortList()
            depositScores()
            awardWinner()
            awardLosers()
            setStage(5)
            setTimeout(closeGame, 10000)
        }
    }, [next])

    useEffect(() => {
        if (!loaded) {return}
        let code = game.current.code
        setTimeout(() => {
            socket.emit('stage', {stage, code, data})
        }, parseInt(game.delay) * 1000)
    }, [stage])

    useEffect(() => {

    }, [])

    useEffect(() => {
        l1.play();
        l2.pause();
        l3.pause();
        getGame()

        return () => {
            console.log("off")
            socket.removeAllListeners();
            l1.pause();
            l2.pause();
            l3.pause()
            closeGame()
        }
    }, [])

    if (loaded) {
        switch (stage) {
            default:
                return <Menu key={refresh} code={game.current.code} players={players.current} next={handleNext} />
            case 1:
                return <PreQuestion key={refresh} question={questions.current[question.current]} next={handleNext} />
            case 2:
                return <Question key={refresh} question={questions.current[question.current]} next={checkAnswered} answered={answered.current} players={players.current} data={data} />
            case 3:
                return <Bars key={refresh} red={red} blue={blue} green={green} yellow={yellow} pink={pink} question={questions.current[question.current]} next={handleNext} />
            case 4:
                return <Stats key={refresh} players={players.current} next={handleNext} />
            case 5:
                return <Finished key={refresh} players={players.current} />
        }
    } else {
        return <Loading />
    }
};

export default Host2;
