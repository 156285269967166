import React from "react";

class Ready extends React.Component {
    render() {
        return (
            <>
                <div className="host-container">
                    <div className="top-bar">
                        <div>NAME - {this.props.user}</div>
                        <div>QUESTION - {this.props.data.currentQuestion} / {this.props.data.totalQuestions}</div>
                        <div>ROOM - {this.props.code}</div>
                    </div>
                    <div className="localFont">
                        GET READY FOR THE NEXT QUESTION
                    </div>
                </div>
            </>
        );
    }
}

export default Ready;