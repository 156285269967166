import React from "react";

class Card extends React.Component {
    render() {
        return (
            <div style={{width: "20vw", textAlign: "-webkit-center"}}>
                <div style={{width: "255px"}} tiltMaxAngleX={5} tiltMaxAngleY={5} glareEnable={true} scale={1.2} glareMaxOpacity={0.5} glareColor="#ffffff" glarePosition="bottom" glareBorderRadius="0">
                    <div className={this.props.shiny ? "card" : "card card-nonshiny"} style={{backgroundImage: "url("+this.props.src+")"}} alt={this.props.alt} />
                </div>
            </div>
        );
    }
}

export default Card;
