import React, {useCallback, useEffect, useState} from "react";
import {authenticationService} from "../Scripts/authentication.service";
import {handleResponse} from "../Scripts/handle-response";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Person from "@mui/icons-material/Person";
import Lock from "@mui/icons-material/Lock";
import Button from "@mui/material/Button";
import Loading from "../Loading/Loading";
import {useSnackbar} from "notistack";

const Edit2 = () => {
    const [user, setUser] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [submit, setSubmit] = useState(false)
    const [submit2, setSubmit2] = useState(false)
    const { enqueueSnackbar } = useSnackbar()

    const handleUpdate = (event) => {
        let temp = user
        temp[event.target.name] = event.target.value
        setUser(temp)
    }

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )
    }

    const getProfile = useCallback(() => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
            body: JSON.stringify({'user':authenticationService.currentUserValue.username})
        };

        fetch(`${process.env.REACT_APP_API_ROUTES}/profile/getUser`, requestOptions)
            .then(handleResponse)
            .then((result) => {
                    let temp = result[0]
                    temp.current_password = ''
                    temp.new_password = '1'
                    temp.confirm_password = '2'
                    setUser(temp)
                    setLoaded(true)
                },
                (error) => {console.error(error)}
            )
    }, [])

    useEffect(() => {
        if (!submit2) {return}

        if (user.new_password !== user.confirm_password) {
            enqueueSnackbar('Passwords do not match', {variant:"error"})
            setSubmit2(false)
            return
        }

        enqueueSnackbar('Confirming With Server', {variant:"info"})
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
            body: JSON.stringify({'user': user})
        };

        fetch(`${process.env.REACT_APP_API_ROUTES}/profile/updatePassword`, requestOptions)
            .then(handleResponse)
            .then((result) => {
                setSubmit2(false)
                enqueueSnackbar('Update Success', {variant:"success"})
            }, (error) => {
                setSubmit2(false)
                enqueueSnackbar('Update Failed', {variant:"error"})
                console.error(error)
            })
    }, [submit2, enqueueSnackbar, user] )

    useEffect(() => {
        if (!submit) {return}

        if (!validateEmail(user.email)) {
            enqueueSnackbar('Invalid Email', {variant:"error"})
            setSubmit(false)
            return
        }

        enqueueSnackbar('Confirming With Server', {variant:"info"})
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
            body: JSON.stringify({'user': user})
        };

        fetch(`${process.env.REACT_APP_API_ROUTES}/profile/updateProfile`, requestOptions)
            .then(handleResponse)
            .then((result) => {
                setSubmit(false)
                enqueueSnackbar('Update Success', {variant:"success"})
                let storedUser = authenticationService.currentUserValue
                storedUser.pic = user.pic
                localStorage.setItem('currentUser', JSON.stringify(storedUser));

            }, (error) => {
                setSubmit(false)
                enqueueSnackbar('Update Failed', {variant:"error"})
                console.error(error)
            })

    }, [submit, enqueueSnackbar, user] )

    useEffect(() => {
        getProfile()
    }, [getProfile])

    if (loaded) {
        return (
            <div className="divide-y divide-white/5">
                <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
                    <div>
                        <h2 className="text-base font-semibold leading-7 text-black">Personal Information</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-400">
                            Use a permanent address where you can receive mail.
                        </p>
                    </div>

                    <div className="md:col-span-2">
                        <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
                            <div className="col-span-full flex items-center gap-x-8">
                                <img
                                    src={user.pic}
                                    alt=""
                                    className="h-24 w-24 flex-none rounded-full bg-gray-800 object-cover"
                                />
                                <div className="w-full">
                                    <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-black">
                                        Avatar
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            type="text"
                                            name="pic"
                                            id="pic"
                                            autoComplete="profile-picture"
                                            onChange={e => handleUpdate(e)}
                                            placeholder={user.pic}
                                            className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                    <p className="mt-2 text-xs leading-5 text-gray-400">JPG, GIF or PNG</p>
                                </div>
                            </div>

                            <div className="sm:col-span-3">
                                <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-black">
                                    First name
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        name="first-name"
                                        id="first-name"
                                        autoComplete="given-name"
                                        placeholder="WIP"
                                        disabled={true}
                                        className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-3">
                                <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-black">
                                    Last name
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        name="last-name"
                                        id="last-name"
                                        autoComplete="family-name"
                                        placeholder="WIP"
                                        disabled={true}
                                        className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                            <div className="col-span-full">
                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-black">
                                    Email address
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        autoComplete="email"
                                        onChange={e => handleUpdate(e)}
                                        placeholder={user.email}
                                        className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                            <div className="col-span-full">
                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-black">
                                    Banner
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="banner"
                                        name="banner"
                                        type="text"
                                        autoComplete="banner"
                                        onChange={e => handleUpdate(e)}
                                        placeholder={user.banner}
                                        className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                            <div className="col-span-full">
                                <label htmlFor="username" className="block text-sm font-medium leading-6 text-black">
                                    Username
                                </label>
                                <div className="mt-2">
                                    <div className="flex rounded-md bg-white/5 ring-1 ring-inset ring-white/10 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
                                            <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm">
                                              kashoot.co.uk/profile/
                                            </span>
                                        <input
                                            type="text"
                                            name="username"
                                            id="username"
                                            autoComplete="username"
                                            className="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-0 p-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            placeholder={user.username}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="mt-8 flex">
                            <button
                                type="submit"
                                disabled={submit || submit2}
                                onClick={() => setSubmit(true)}
                                className="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-black shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>

                <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
                    <div>
                        <h2 className="text-base font-semibold leading-7 text-black">Change password</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-400">
                            Update your password associated with your account.
                        </p>
                    </div>

                    <div className="md:col-span-2">
                        <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
                            <div className="col-span-full">
                                <label htmlFor="current-password" className="block text-sm font-medium leading-6 text-black">
                                    Current password
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="current-password"
                                        name="current_password"
                                        onChange={e => handleUpdate(e)}
                                        type="password"
                                        placeholder="Current Password"
                                        autoComplete="current-password"
                                        className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                            <div className="col-span-full">
                                <label htmlFor="new-password" className="block text-sm font-medium leading-6 text-black">
                                    New password
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="new-password"
                                        name="new_password"
                                        onChange={e => handleUpdate(e)}
                                        type="password"
                                        placeholder="New Password"
                                        autoComplete="new-password"
                                        className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                            <div className="col-span-full">
                                <label htmlFor="confirm-password" className="block text-sm font-medium leading-6 text-black">
                                    Confirm password
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="confirm-password"
                                        name="confirm_password"
                                        onChange={e => handleUpdate(e)}
                                        type="password"
                                        placeholder="Confirm New Password"
                                        autoComplete="new-password"
                                        className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="mt-8 flex">
                            <button
                                disabled={submit ||submit2}
                                onClick={() => setSubmit2(true)}
                                type="submit"
                                className="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-black shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>

                <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
                    <div>
                        <h2 className="text-base font-semibold leading-7 text-black">Log out other sessions</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-400">
                            Please enter your password to confirm you would like to log out of your other sessions across all of
                            your devices.
                        </p>
                    </div>

                    <div className="md:col-span-2">
                        <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
                            <div className="col-span-full">
                                <label htmlFor="logout-password" className="block text-sm font-medium leading-6 text-black">
                                    Your password
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="logout-password"
                                        name="password"
                                        type="password"
                                        autoComplete="current-password"
                                        placeholder="WIP"
                                        disabled={true}
                                        className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="mt-8 flex">
                            <button
                                type="submit"
                                disabled={true}
                                className="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-black shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                            >
                                Log out other sessions
                            </button>
                        </div>
                    </div>
                </div>

                <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
                    <div>
                        <h2 className="text-base font-semibold leading-7 text-black">Delete account</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-400">
                            No longer want to use our service? You can delete your account here. This action is not reversible.
                            All information related to this account will be deleted permanently.
                        </p>
                    </div>

                    <div className="flex items-start md:col-span-2">
                        <button
                            type="submit"
                            disabled={true}
                            className="rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-black shadow-sm hover:bg-red-400"
                        >
                            Yes, delete my account
                        </button>
                    </div>
                </div>
            </div>
        )
    } else {
        return <Loading />
    }
};

export default Edit2;
