import React, {useCallback, useEffect, useState} from "react";
import {useParams, Link} from "react-router-dom";
import {authenticationService} from "../Scripts/authentication.service";
import {handleResponse} from "../Scripts/handle-response";
import Loading from "../Loading/Loading";
import {PencilSquareIcon, UserPlusIcon} from "@heroicons/react/24/solid";

const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
]

function Profile() {
    const { id } = useParams()
    const [user, setUser] = useState([])
    // todo:add Cards
    // eslint-disable-next-line no-unused-vars
    const [cards, setCards] = useState([])
    // todo:add page
    // eslint-disable-next-line no-unused-vars
    const [page, setPage] = useState(0)
    const [loaded, setLoaded] = useState(false)

    const getUser = useCallback(() => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
            body: JSON.stringify({'user':id})
        };

        fetch(`${process.env.REACT_APP_API_ROUTES}/profile/getUser`, requestOptions)
            .then(handleResponse)
            .then((result) => {
                    setUser(result[0])
                }, (error) => {console.error(error)}
            )
    }, [id])

    const getCards = useCallback(() => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
            body: JSON.stringify({'id':user.id})
        };

        fetch(`${process.env.REACT_APP_API_ROUTES}/card/getCard`, requestOptions)
            .then(handleResponse)
            .then((result) => {
                    setCards(result)
                    setLoaded(true)
                },
                (error) => {
                    console.log(error)
                }
            )
    }, [user])

    useEffect( () => {
        getCards()
    }, [user, getCards])

    useEffect(() => {
        getUser()
    }, [getUser])


    if (loaded) {
        const last = new Date(user.last_login)
        return (
            <div className="-mt-10 -mx-4 sm:-mx-6 md:-mx-8">
                <div>
                    <div>
                        <img className="h-40 w-full object-cover lg:h-60" src={user.banner} alt="" />
                    </div>
                    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                        <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
                            <div className="flex">
                                <img
                                    className="h-24 w-24 rounded-full ring-4 ring-gray-50 object-cover sm:h-32 sm:w-32"
                                    src={user.pic}
                                    alt=""
                                />
                            </div>
                            <div className="mt-6 sm:flex sm:min-w-0 sm:flex-1 sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
                                <div className="mt-6 min-w-0 flex-1 sm:hidden 2xl:block">
                                    <h1 className="truncate text-2xl font-bold text-gray-900">{user.username}</h1>
                                </div>
                                <div className="mt-6 flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-x-4 sm:space-y-0">
                                    {user.id === authenticationService.currentUserValue.id
                                        ? (
                                            <Link to={"/profile/edit/"}>
                                                <button
                                                    type="button"
                                                    className="inline-flex justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                                >
                                                    <PencilSquareIcon className="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                                                    Edit
                                                </button>
                                            </Link>
                                        ) : (
                                            <button
                                                type="button"
                                                className="inline-flex justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                            >
                                                <UserPlusIcon className="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                                                Add
                                            </button>
                                        )}
                                </div>
                            </div>
                        </div>
                        <div className="mt-6 hidden min-w-0 flex-1 sm:block 2xl:hidden">
                            <h1 className="truncate text-2xl font-bold text-gray-900">{user.username}</h1>
                        </div>
                    </div>
                </div>

                {/* Tabs */}
                <div className="mt-6 sm:mt-2 2xl:mt-5">
                    <div className="border-b border-gray-200">
                        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                                <div
                                    className={page.current
                                        ? 'border-pink-500 text-gray-900'
                                        : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium'}
                                >
                                    Profile
                                </div>
                                <div
                                    className={page.current
                                        ? 'border-pink-500 text-gray-900'
                                        : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium'}
                                >
                                    Cards
                                </div>
                                <div
                                    className={page.current
                                        ? 'border-pink-500 text-gray-900'
                                        : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium'}
                                >
                                    Quiz's
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>

                {/* Description list */}
                <div className="mx-auto mt-6 max-w-7xl px-4 sm:px-6 lg:px-8">
                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                        <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">Points</dt>
                            <dd className="mt-1 text-sm text-gray-900">{user.points}</dd>
                        </div>
                        <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">Minus</dt>
                            <dd className="mt-1 text-sm text-gray-900">{user.minus}</dd>
                        </div>
                        <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">Wins</dt>
                            <dd className="mt-1 text-sm text-gray-900">{user.wins}</dd>
                        </div>
                        <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">Looses</dt>
                            <dd className="mt-1 text-sm text-gray-900">{user.looses}</dd>
                        </div>
                        <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">Traps</dt>
                            <dd className="mt-1 text-sm text-gray-900">{user.traps}</dd>
                        </div>
                        <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">Streak</dt>
                            <dd className="mt-1 text-sm text-gray-900">{user.streak}</dd>
                        </div>
                        <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">Fast Hand</dt>
                            <dd className="mt-1 text-sm text-gray-900">{user.first}</dd>
                        </div>
                        <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">Slowest Hand</dt>
                            <dd className="mt-1 text-sm text-gray-900">{user.last}</dd>
                        </div>
                        <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">Correct</dt>
                            <dd className="mt-1 text-sm text-gray-900">{user.correct}</dd>
                        </div>
                        <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">Wrong</dt>
                            <dd className="mt-1 text-sm text-gray-900">{user.wrong}</dd>
                        </div>
                        <div className="sm:col-span-2">
                            <dt className="text-sm font-medium text-gray-500">About</dt>
                            <dd className="mt-1 max-w-prose space-y-5 text-sm text-gray-900">Last online: {last.getDate()} {months[last.getMonth()]} {last.getFullYear()}</dd>
                        </div>
                    </dl>
                </div>

                {/* Friends list */}
                <div className="mx-auto mt-8 max-w-7xl px-4 pb-12 sm:px-6 lg:px-8">
                    <h2 className="text-sm font-medium text-gray-500">Friends</h2>
                    <div className="mt-1 grid grid-cols-1 gap-4 sm:grid-cols-2">
                        <div
                            className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-pink-500 focus-within:ring-offset-2 hover:border-gray-400"
                        >
                            <div className="flex-shrink-0">
                                <img className="h-10 w-10 rounded-full object-cover" src={user.pic} alt="" />
                            </div>
                            <div className="min-w-0 flex-1">
                                <div className="focus:outline-none">
                                    <span className="absolute inset-0" aria-hidden="true" />
                                    <p className="text-sm font-medium text-gray-900">WIP</p>
                                    <p className="truncate text-sm text-gray-500">Work in progress</p>
                                </div>
                            </div>
                        </div>
                        {/*team.map((person) => (
                            <div
                                key={person.handle}
                                className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-pink-500 focus-within:ring-offset-2 hover:border-gray-400"
                            >
                                <div className="flex-shrink-0">
                                    <img className="h-10 w-10 rounded-full" src={person.imageUrl} alt="" />
                                </div>
                                <div className="min-w-0 flex-1">
                                    <a href="#" className="focus:outline-none">
                                        <span className="absolute inset-0" aria-hidden="true" />
                                        <p className="text-sm font-medium text-gray-900">{person.name}</p>
                                        <p className="truncate text-sm text-gray-500">{person.role}</p>
                                    </a>
                                </div>
                            </div>
                        ))*/}
                    </div>
                </div>
            </div>
        )
    } else {
        return (<Loading />)
    }
}

export default Profile;
