import React from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

class CreateQuiz extends React.Component {
    render() {
        return (
            <div className="login">
                <div className="box">
                    <div style={{alignItems: "center", justifyContent: "center", fontSize: "50px", height: "100%", display: "flex", flexDirection: "column"}}>
                        <TextField variant="outlined" color="secondary" focused label="Title" style={{width: "100%"}} name="title" onChange={this.props.handleQuiz}/>
                        <TextField variant="outlined" color="secondary" focused label="Pic" style={{width: "100%"}} name="pic" className="separator" onChange={this.props.handleQuiz}/>
                        <Button className="separator" variant="contained" color="secondary" style={{width: "100%"}} onClick={this.props.finishSetup}>Create</Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default CreateQuiz;