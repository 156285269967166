import React from "react";
import CreateQuiz from "./components/createQuiz";
import CreateQuestion from "./components/createQuestion";
import Button from "@mui/material/Button";
import {authenticationService} from "../Scripts/authentication.service";
import {handleResponse} from "../Scripts/handle-response";

class Create extends React.Component {
    constructor(props) {
        super(props);

        this.handleQuiz = this.handleQuiz.bind(this)
        this.handleQuestion = this.handleQuestion.bind(this)
        this.newQuestion = this.newQuestion.bind(this)
        this.finishSetup = this.finishSetup.bind(this)

        this.state = {
            quiz: {title:"", user:authenticationService.currentUserValue.id, questions:0, pic:"https://i.imgur.com/IlyL6uw.png"},
            questions: [],
            setup: true
        }
    }

    handleQuiz(event) {
        let temp = this.state.quiz
        temp[event.target.name] = event.target.value
        this.setState({
            quiz: temp
        })
    }

    handleQuestion(event, index) {
        let questions = this.state.questions
        let question = questions[index]
        if (["1","2","3","4"].includes(event.target.name)) {
            question.answers[parseInt(event.target.name) - 1] = event.target.value
            questions[index] = question
        } else {
            question[event.target.name] = event.target.value
            questions[index] = question
        }
        this.setState({
            questions: questions
        })
    }

    handleCorrect = (event, index, value) => {
        let questions = this.state.questions
        let question = questions[index]
        let correct = question.correct
        let trick = question.trick
        if (correct.includes(0)) {
            correct = []
        }
        if (correct.includes(5)) {
            correct = []
            trick = []
        }
        if (correct.includes(value)) {
            if (correct.indexOf(value) > -1) {
                correct.splice(correct.indexOf(value), 1);
            }
        } else {
            correct.push(value)
            if (trick.includes(value)) {
                if (trick.indexOf(value) > -1) {
                    trick.splice(trick.indexOf(value), 1);
                }
            }
        }
        question.trick = trick
        question.correct = correct
        questions[index] = question
        this.setState({
            questions: questions
        })
    }

    handleTrick = (event, index, value) => {
        let questions = this.state.questions
        let question = questions[index]
        let trick = question.trick
        let correct = question.correct
        if (trick.includes(0)) {
            trick = []
        }
        if (trick.includes(value)) {
            if (trick.indexOf(value) > -1) {
                trick.splice(trick.indexOf(value), 1);
            }
        } else {
            trick.push(value)
            if (correct.includes(value)) {
                if (correct.indexOf(value) > -1) {
                    correct.splice(correct.indexOf(value), 1);
                }
            }
            if (trick.length >= 4) {
                correct = [5]
            }
        }
        question.trick = trick
        question.correct = correct
        questions[index] = question
        this.setState({
            questions: questions
        })
    }


    newQuestion() {
        let questions = this.state.questions
        let quiz = this.state.quiz
        quiz.questions ++
        questions.push({quiz: 1, question: "", img: "", answers: ["", "", "", ""], correct: [0], trick: [0], type: 2, alert: ""})
        this.setState({
            questions: questions,
            quiz: quiz
        }, () => {console.log(this.state.questions)})
    }

    finishSetup() {
        this.newQuestion()
        this.setState({
            setup: false,
        })
    }

    finishCreate = () => {
        //this.props.enqueueSnackbar('Confirming With Server', {variant:"info"});
        let temp = this.state.quiz
         const options = {
             method: 'POST',
             headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
             body: JSON.stringify({'quiz':temp})
         }

        fetch(`${process.env.REACT_APP_API_ROUTES}/quiz/addQuiz`, options)
            .then(handleResponse)
            .then((result) => {
                    this.state.questions.forEach(item => {
                        item.quiz = result.insertId
                    })

                    const questionOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
                        body: JSON.stringify({'questions':this.state.questions})
                    }

                    fetch(`${process.env.REACT_APP_API_ROUTES}/question/addQuestions`, questionOptions)
                        .then(handleResponse)
                        .then(result => {
                            //this.props.enqueueSnackbar('Creation Success', {variant:"success"});
                            console.log(result)
                            let { from } =  { from: { pathname: "/quizzes" } }
                            this.props.history.push(from)
                        })
                },
                (error) => {
                    //this.props.enqueueSnackbar('Creation Failed', {variant:"error"});
                    console.log(error)
                }
            )
    }

    render() {
        const {setup, questions} = this.state
        return (
            <>
                {setup ? <CreateQuiz handleQuiz={this.handleQuiz} finishSetup={this.finishSetup} /> : (
                    <div className="create">
                        {questions.map((item, index) => (
                            <CreateQuestion item={item} index={index} handleQuestion={this.handleQuestion} handleTrick={this.handleTrick} handleCorrect={this.handleCorrect} />
                        ))}
                        <div style={{display: "flex"}}>
                            <Button style={{marginRight: "10px"}} variant="contained" color="primary" onClick={this.newQuestion}>ADD</Button>
                            <Button variant="contained" color="primary" onClick={this.finishCreate}>Finish</Button>
                        </div>
                    </div>
                )}
            </>
        )
    }
}

export default Create;
