import React, {Fragment, useCallback, useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {authenticationService} from "../Scripts/authentication.service";
import {handleResponse} from "../Scripts/handle-response";
import { Dialog, Transition } from '@headlessui/react'
import {
    HomeIcon,
    PlayIcon,
    CommandLineIcon,
    PencilSquareIcon,
    SwatchIcon,
    UsersIcon,
    CreditCardIcon,
    AdjustmentsVerticalIcon,
    MapIcon,
    Bars3BottomLeftIcon,
    XMarkIcon,
    Bars3Icon,
    QrCodeIcon,
    PowerIcon,
    CubeTransparentIcon
} from '@heroicons/react/24/outline'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function Header() {
    const [user] = useState(authenticationService.currentUserValue)
    const [sidebarOpen, setSidebarOpen] = useState(false)
    // eslint-disable-next-line no-unused-vars
    const [data, setData] = useState({inGame: false, code: 69420})
    const location = useLocation();
    const [navigation, setNavigation] = useState([
        { name: 'Home', link: '/', icon: HomeIcon, current: true, perms: null },
        { name: 'Play', link: '/join', icon: PlayIcon, current: false, perms: null },
        { name: 'Host', link: '/quizzes', icon: CommandLineIcon, current: false, perms: "host" },
        { name: 'Create', link: '/creator', icon: PencilSquareIcon, current: false, perms: "create" },
        { name: 'Cards', link: '/cards', icon: SwatchIcon, current: false, perms: null },
        { name: 'Users', link: '/profile', icon: UsersIcon, current: false, perms: null },
        { name: 'Store', link: '/store', icon: CreditCardIcon, current: false, perms: null },
        { name: 'Admin', link: '/admin', icon: AdjustmentsVerticalIcon, current: false, perms: "admin" },
        { name: 'Roadmap', link: '/roadmap', icon: MapIcon, current: false, perms: null },
        { name: 'Patch Notes', link: '/patchnotes', icon: Bars3BottomLeftIcon, current: false, perms: null },
        { name: 'Pee Pee Poo Poo Maia Bingo', link: '/bingo', icon: QrCodeIcon, current: false, perms: "friend" },
    ])

    const checkInGame = useCallback(() => {
        console.log(location)
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
            body: JSON.stringify({'user': user.id})
        };

        fetch(`${process.env.REACT_APP_API_ROUTES}/game/checkIn`, requestOptions)
            .then(handleResponse)
            .then((result) => {
                    setData(result)
                }
            )
    }, [user, location])

    useEffect(() => {
        const updatedNavigation = navigation.map(item =>
            item.link === location.pathname ? { ...item, current: true } : { ...item, current: false }
        );
        setNavigation(updatedNavigation)
        if (location.pathname !== "/play" && location.pathname !== "/host") {
            checkInGame()
        }
    }, [location, checkInGame]);

    const logout = () => {
        authenticationService.logout(authenticationService.currentUserValue);
        window.location.reload();
    }

    return (
        <>
            {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-gray-50">
        <body class="h-full">
        ```
      */}
            <div>
                <Transition.Root show={sidebarOpen} as={Fragment}>
                    <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
                        <Transition.Child
                            as={Fragment}
                            enter="transition-opacity ease-linear duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity ease-linear duration-300"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-gray-900/80" />
                        </Transition.Child>

                        <div className="fixed inset-0 flex">
                            <Transition.Child
                                as={Fragment}
                                enter="transition ease-in-out duration-300 transform"
                                enterFrom="-translate-x-full"
                                enterTo="translate-x-0"
                                leave="transition ease-in-out duration-300 transform"
                                leaveFrom="translate-x-0"
                                leaveTo="-translate-x-full"
                            >
                                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-in-out duration-300"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in-out duration-300"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                                            <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                                                <span className="sr-only">Close sidebar</span>
                                                <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                            </button>
                                        </div>
                                    </Transition.Child>
                                    {/* Sidebar component, swap this element with another sidebar if you like */}
                                    <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-2">
                                        <div className="flex h-16 shrink-0 items-center">
                                            <img
                                                className="h-8 w-auto"
                                                src="https://i.imgur.com/uvlMpvK.png"
                                                alt="Kashoot"
                                            />
                                        </div>
                                        <nav className="flex flex-1 flex-col">
                                            <ul className="flex flex-1 flex-col gap-y-7">
                                                <li>
                                                    <ul className="-mx-2 space-y-1">
                                                        {navigation.map((item) => {
                                                            if (!item.perms || user[item.perms]) {
                                                                return (
                                                                    <li key={item.name}>
                                                                        <Link to={item.link}>
                                                                            <div
                                                                                className={classNames(
                                                                                    item.current
                                                                                        ? 'bg-gray-50 text-indigo-600'
                                                                                        : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                                                                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                                                                )}
                                                                            >
                                                                                <item.icon
                                                                                    className={classNames(
                                                                                        item.current ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600',
                                                                                        'h-6 w-6 shrink-0'
                                                                                    )}
                                                                                    aria-hidden="true"
                                                                                />
                                                                                {item.name}
                                                                            </div>
                                                                        </Link>
                                                                    </li>
                                                                )
                                                            } else {
                                                                return ""
                                                            }
                                                        })}
                                                    </ul>
                                                </li>
                                                <li className="-mx-6 mt-auto">
                                                    <div className="flex items-center cursor-pointer gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50">
                                                        <CubeTransparentIcon
                                                            className="text-gray-400 group-hover:text-indigo-600 h-8 w-8 shrink-"
                                                            aria-hidden="true"
                                                        />
                                                        <span aria-hidden="true">{user.mealdealz}</span>
                                                    </div>
                                                    <div onClick={logout} className="flex items-center cursor-pointer gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50">
                                                        <PowerIcon
                                                            className="text-gray-400 group-hover:text-indigo-600 h-8 w-8 shrink-"
                                                            aria-hidden="true"
                                                        />
                                                        <span aria-hidden="true">Logout</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </Dialog>
                </Transition.Root>

                {/* Static sidebar for desktop */}
                <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
                    {/* Sidebar component, swap this element with another sidebar if you like */}
                    <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6">
                        <div className="flex h-16 shrink-0 items-center">
                            <img
                                className="h-20 w-auto"
                                src="https://i.imgur.com/uvlMpvK.png"
                                alt="Kashoot"
                            />
                        </div>
                        <nav className="flex flex-1 flex-col">
                            <ul className="flex flex-1 flex-col gap-y-7">
                                <li>
                                    <ul className="-mx-2 space-y-1">
                                        {navigation.map((item) => {
                                            if (!item.perms || user[item.perms]) {
                                                return (
                                                    <li key={item.name}>
                                                        <Link to={item.link}>
                                                            <div
                                                                className={classNames(
                                                                    item.current
                                                                        ? 'bg-gray-50 text-indigo-600'
                                                                        : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                                                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                                                )}
                                                            >
                                                                <item.icon
                                                                    className={classNames(
                                                                        item.current ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600',
                                                                        'h-6 w-6 shrink-0'
                                                                    )}
                                                                    aria-hidden="true"
                                                                />
                                                                {item.name}
                                                            </div>
                                                        </Link>
                                                    </li>
                                                )
                                            } else {
                                                return ""
                                            }
                                        })}
                                    </ul>
                                </li>
                                <li className="-mx-6 mt-auto">
                                    <div className="flex items-center cursor-pointer gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50">
                                        <CubeTransparentIcon
                                            className="text-gray-400 group-hover:text-indigo-600 h-8 w-8 shrink-"
                                            aria-hidden="true"
                                        />
                                        <span aria-hidden="true">{user.mealdealz}</span>
                                    </div>
                                    <div onClick={logout} className="flex items-center cursor-pointer gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50">
                                        <PowerIcon
                                            className="text-gray-400 group-hover:text-indigo-600 h-8 w-8 shrink-"
                                            aria-hidden="true"
                                        />
                                        <span aria-hidden="true">Logout</span>
                                    </div>
                                    <Link to={"/profile/"+user.username+""}>
                                        <div className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50">
                                            <img
                                                className="h-8 w-8 rounded-full bg-gray-50 object-cover"
                                                src={user.pic}
                                                alt=""
                                            />
                                            <span className="sr-only">Your profile</span>
                                            <span aria-hidden="true">{user.username}</span>
                                        </div>
                                    </Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>

                <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-white px-4 py-4 shadow-sm sm:px-6 lg:hidden">
                    <button type="button" className="-m-2.5 p-2.5 text-gray-700 lg:hidden" onClick={() => setSidebarOpen(true)}>
                        <span className="sr-only">Open sidebar</span>
                        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                    </button>
                    <div className="flex-1 text-sm font-semibold leading-6 text-gray-900">Dashboard</div>
                    <Link to={"/profile/"+user.username+""}>
                        <span className="sr-only">Your profile</span>
                        <img
                            className="h-8 w-8 rounded-full bg-gray-50"
                            src={user.pic}
                            alt=""
                        />
                    </Link>
                </div>
            </div>
            {/*Old header
        <div className="header">
            <div className="box">
                <div className="left">
                    <Link to="/">
                        <Button style={{color:"#9c27b0"}}>
                            <Home color="secondary" />
                            Home 2
                        </Button>
                    </Link>

                    <div className="play-drop">
                        <Button style={{color:"#9c27b0"}}>
                            <PlayArrow color="secondary" />
                            Play
                        </Button>
                        <div className="play-drop-hover">
                            <Link to="/join">
                                <Button style={{color:"#9c27b0"}}>
                                    <Join color="secondary" />
                                    Join
                                </Button>
                            </Link>
                            <Link className={user.host ? "" : "hide"} to="/quizzes">
                                <Button style={{color:"#9c27b0"}}>
                                    <Quizzes color="secondary" />
                                    Host
                                </Button>
                            </Link>
                            <Link className={user.create ? "" : "hide"} to="/creator">
                                <Button style={{color:"#9c27b0"}}>
                                    <Create color="secondary" />
                                    Create
                                </Button>
                            </Link>
                        </div>
                    </div>

                    <div className="social-drop">
                        <Button style={{color:"#9c27b0"}}>
                            <PeopleAltIcon color="secondary" />
                            Social
                        </Button>
                        <div className="social-drop-hover">
                            <Link to="/cards">
                                <Button style={{color:"#9c27b0"}}>
                                    <Cards color="secondary" />
                                    Cards
                                </Button>
                            </Link>
                            <Link to="/profile">
                                <Button style={{color:"#9c27b0"}}>
                                    <User color="secondary" />
                                    Users
                                </Button>
                            </Link>
                        </div>
                    </div>

                    <Link to="/store">
                        <Button style={{color:"#9c27b0"}}>
                            <Store color="secondary" />
                            Store
                        </Button>
                    </Link>

                    <Link className={user.admin ? "" : "hide"} to="/admin">
                        <Button variant="contained" style={{color:"#9c27b0"}}>
                            <Settings color="secondary" />
                            Admin
                        </Button>
                    </Link>
                    <Link to="/roadmap">
                        <Button variant="contained" style={{color:"#9c27b0"}}>
                            <Settings color="secondary" />
                            Roadmap
                        </Button>
                    </Link>
                    <Link to="/patchnotes">
                        <Button variant="contained" style={{color:"#9c27b0"}}>
                            <Settings color="secondary" />
                            Patch Notes
                        </Button>
                    </Link>
                </div>
                <div className="right">
                    <div className="user-drop">
                        <Avatar alt={user.username} src={user.pic} className="profile"/>
                        <div className="user-drop-hover">
                            <div className="user-drop-hover-box">
                                <Link to={"/profile/"+user.username+""}>
                                    <Button>
                                        Profile
                                    </Button>
                                </Link>
                                <Link to={"/profile/edit/"}>
                                    <Button>
                                        Edit
                                    </Button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <Button variant="contained" style={{color:"#9c27b0"}}>
                        <TokenIcon color="secondary" />
                        {user.mealdealz}
                    </Button>
                    <Link to="/login">
                        <Button onClick={logout} style={{color:"white"}}>
                            <Logout color="secondary" />
                        </Button>
                    </Link>
                </div>
            </div>
            <div className={data.inGame ? "in-game" : "hidden"}>
                Currently In Game {data.code} <Button style={{color: "white"}} variant="contained" onClick={() => {this.joinGame(data.code)}}>Rejoin</Button>
            </div>
        </div>*/}
        </>
    );
}

export default Header;
