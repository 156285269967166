import React from "react";

const Title = ({title, desc}) => {
    return (
        <div className="max-w-2xl pb-10">
            <h3 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">{title}</h3>
            <p className="mt-6 text-lg leading-8 text-gray-600">
                {desc}
            </p>
        </div>
    );
};

export default Title;
