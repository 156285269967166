import React, {useEffect, useState} from "react";
import {handleResponse} from "../Scripts/handle-response";
import {Link, useNavigate} from "react-router-dom";
import {ExclamationCircleIcon} from "@heroicons/react/20/solid";

const NewUser = () => {
    const [user, setUser] = useState('')
    const [email, setEmail] = useState('')
    const [pass, setPass] = useState('')
    const [pass2, setPass2] = useState('')
    const [submit, setSubmit] = useState(false)
    // eslint-disable-next-line no-unused-vars
    const [error, setError] = useState('')
    const [taken, setTaken] = useState(false)
    const [missMatch, setMissMatch] = useState(false)
    const [invalid, setInvalid] = useState(false)
    let navigate = useNavigate();

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )
    }

    useEffect(() => {
        if (invalid) {
            if (validateEmail(email)) {
                setInvalid(false)
            }
        }
    }, [email, invalid])

    useEffect(() => {
        if (!submit) {return}

        if (!validateEmail(email)) {
            setInvalid(true)
            setSubmit(false)
            return
        }

        if (pass !== pass2) {
            setMissMatch(true)
            setSubmit(false)
            return
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json'},
            body: JSON.stringify({ 'user' : user })
        };

        fetch(`${process.env.REACT_APP_API_ROUTES}/profile/checkUser`, requestOptions)
            .then(handleResponse)
            .then((result) => {
                if (result) {
                    setTaken(true)
                    setSubmit(false)
                } else {
                    const requestOptions2 = {
                        method: 'POST',
                        headers: {'Content-Type': 'application/json'},
                        body: JSON.stringify({'user': user, 'email': email, 'pass': pass})
                    };

                    fetch(`${process.env.REACT_APP_API_ROUTES}/profile/newUser`, requestOptions2)
                        .then(handleResponse)
                        .then((result) => {
                            console.log(result)
                            navigate('/login')
                        }, (error) => {
                            setError(error)
                        })
                }
            }, (error) => {
                setError(error)
            })
    }, [submit, pass, user, email, pass2, navigate])

    const signup = () => {
        setSubmit(true)
    }

    return (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <img
                    className="mx-auto h-40 w-auto"
                    src="https://i.imgur.com/uvlMpvK.png"
                    alt="Red Corp"
                />
                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                    Sign up to our system
                </h2>
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <div className="space-y-6">
                    <div>
                        <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                            Username
                        </label>
                        <div className="mt-2 relative ">
                            <input
                                id="username"
                                name="username"
                                type="username"
                                placeholder="User"
                                autoComplete="username"
                                required
                                onChange={e => setUser(e.target.value)}
                                className={taken
                                    ? "block w-full rounded-md border-0 p-2 text-red-900 shadow-sm ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-2 focus:ring-inset focus:ring-red-500 sm:text-sm sm:leading-6"
                                    : "block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                }
                            />
                            {taken && (
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                                </div>
                            )}
                        </div>
                        <p className="mt-2 text-sm text-red-600" id="email-error">
                            {taken ? "Username Taken." : "⠀"}
                        </p>
                    </div>

                    <div>
                        <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                            Email
                        </label>
                        <div className="mt-2 relative ">
                            <input
                                id="email"
                                name="email"
                                type="email"
                                placeholder="Email"
                                autoComplete="email"
                                required
                                onChange={e => setEmail(e.target.value)}
                                className={invalid
                                    ? "block w-full rounded-md border-0 p-2 text-red-900 shadow-sm ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-2 focus:ring-inset focus:ring-red-500 sm:text-sm sm:leading-6"
                                    : "block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                }
                            />
                            {invalid && (
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                                </div>
                            )}
                        </div>
                        <p className="mt-2 text-sm text-red-600" id="email-error">
                            {invalid ? "Invalid Email." : "⠀"}
                        </p>
                    </div>

                    <div>
                        <div className="flex items-center justify-between">
                            <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                Password
                            </label>
                        </div>
                        <div className="relative mt-2">
                            <input
                                id="password"
                                name="password"
                                placeholder="Password"
                                type="password"
                                autoComplete="new-password"
                                required
                                onChange={e => setPass(e.target.value)}
                                className={missMatch
                                    ? "block w-full rounded-md border-0 p-2 text-red-900 shadow-sm ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-2 focus:ring-inset focus:ring-red-500 sm:text-sm sm:leading-6"
                                    : "block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                }
                            />
                            {missMatch && (
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                                </div>
                            )}
                        </div>
                        <p className="mt-2 text-sm text-red-600" id="email-error">
                            {missMatch ? "Passwords dont match." : "⠀"}
                        </p>
                    </div>

                    <div>
                        <div className="flex items-center justify-between">
                            <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                Confirm Password
                            </label>
                        </div>
                        <div className="relative mt-2">
                            <input
                                id="password2"
                                name="password2"
                                placeholder="Confirm Password"
                                type="password"
                                autoComplete="new-password"
                                required
                                onChange={e => setPass2(e.target.value)}
                                className={missMatch
                                    ? "block w-full rounded-md border-0 p-2 text-red-900 shadow-sm ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-2 focus:ring-inset focus:ring-red-500 sm:text-sm sm:leading-6"
                                    : "block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                }
                            />
                            {missMatch && (
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                                </div>
                            )}
                        </div>
                        <p className="mt-2 text-sm text-red-600" id="email-error">
                            {missMatch ? "Passwords dont match." : "⠀"}
                        </p>

                    </div>

                    <div>
                        <button
                            type="submit"
                            disabled={submit}
                            onClick={signup}
                            className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Create
                        </button>
                    </div>

                    <p className="mt-10 text-center text-sm text-gray-500">
                        Already signed up?{' '}
                        <Link to="/login">
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
                                Back to login.
                            </a>
                        </Link>

                    </p>
                </div>
            </div>
            {/* Old Signup
                 <div className="login">
                {taken ? (
                    <Alert style={{marginBottom: "20px"}} severity="error" variant="filled">
                        <AlertTitle>Error</AlertTitle>
                        Username Taken
                    </Alert>
                ) : ""}
                {missMatch ? (
                    <Alert style={{marginBottom: "20px"}} severity="error" variant="filled">
                        <AlertTitle>Error</AlertTitle>
                        Passwords do not match
                    </Alert>
                ) : ""}
                <div className="box">
                    <TextField
                        error={error ? "error" : ""}
                        label="Username"
                        color="secondary"
                        variant="outlined"
                        focused
                        onChange={this.handleUser}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Person color="secondary" />
                                </InputAdornment>
                            ),
                        }}/>
                    <TextField
                        className="separator"
                        error={error ? "error" : ""}
                        type="password"
                        label="Password"
                        variant="outlined"
                        color="secondary"
                        focused
                        onChange={this.handlePass}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Lock color="secondary" />
                                </InputAdornment>
                            ),
                        }}/>
                    <TextField
                        className="separator"
                        error={error ? "error" : ""}
                        type="password"
                        label="Confirm Password"
                        color="secondary"
                        variant="outlined"
                        focused
                        onChange={this.handlePass2}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Lock color="secondary" />
                                </InputAdornment>
                            ),
                        }}/>
                    <Button className="separator" variant="contained" color="secondary" disabled={submit} onClick={(e) => this.create(e)}>Create</Button>
                    <Link to="/login"><Button className="separator" style={{width: "100%"}} disabled={submit} variant="contained" color="secondary">Back To Login</Button></Link>
                </div>
            </div>
                 */}
        </div>
    );
};

export default NewUser;
