import React from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import Add from "@mui/icons-material/Check"
import Remove from "@mui/icons-material/Close"

class CreateQuiz extends React.Component {
    render() {
        return (
            <div className={this.props.item.correct.includes(5) ? "create-container fake" : "create-container fake fake-hide"}>
                <TextField variant="outlined" label="Question" name="question" onChange={(e) => this.props.handleQuestion(e, this.props.index)}/>
                <TextField variant="outlined" label="Image" name="img" className="spacer" onChange={(e) => this.props.handleQuestion(e, this.props.index)}/>
                <div style={{display: "flex"}}>
                    <TextField variant="outlined" label="Answer 1" name="1" className="spacer answer1" onChange={(e) => this.props.handleQuestion(e, this.props.index)}/>
                    <div className="correct-wrong">
                        <Button color="primary" variant={this.props.item.correct.includes(1) ? "contained" : "outlined"} onClick={(e) => this.props.handleCorrect(e, this.props.index, 1)}><Add/></Button>
                        <Button color="primary" variant={this.props.item.trick.includes(1) ? "contained" : "outlined"} onClick={(e) => this.props.handleTrick(e, this.props.index, 1)}><Remove/></Button>
                    </div>
                    <TextField variant="outlined" label="Answer 2" name="2" className="spacer answer2" onChange={(e) => this.props.handleQuestion(e, this.props.index)}/>
                    <div className="correct-wrong">
                        <Button color="primary" variant={this.props.item.correct.includes(2) ? "contained" : "outlined"} onClick={(e) => this.props.handleCorrect(e, this.props.index, 2)}><Add/></Button>
                        <Button color="primary" variant={this.props.item.trick.includes(2) ? "contained" : "outlined"} onClick={(e) => this.props.handleTrick(e, this.props.index, 2)}><Remove/></Button>
                    </div>
                    <TextField variant="outlined" label="Answer 3" name="3" className="spacer answer3" onChange={(e) => this.props.handleQuestion(e, this.props.index)}/>
                    <div className="correct-wrong">
                        <Button color="primary" variant={this.props.item.correct.includes(3) ? "contained" : "outlined"} onClick={(e) => this.props.handleCorrect(e, this.props.index, 3)}><Add/></Button>
                        <Button color="primary" variant={this.props.item.trick.includes(3) ? "contained" : "outlined"} onClick={(e) => this.props.handleTrick(e, this.props.index, 3)}><Remove/></Button>
                    </div>
                    <TextField variant="outlined" label="Answer 4" name="4" className="spacer answer4" onChange={(e) => this.props.handleQuestion(e, this.props.index)}/>
                    <div className="correct-wrong">
                        <Button color="primary" variant={this.props.item.correct.includes(4) ? "contained" : "outlined"} onClick={(e) => this.props.handleCorrect(e, this.props.index, 4)}><Add/></Button>
                        <Button color="primary" variant={this.props.item.trick.includes(4) ? "contained" : "outlined"} onClick={(e) => this.props.handleTrick(e, this.props.index, 4)}><Remove/></Button>
                    </div>
                </div>
                <TextField variant="outlined" label="Alert" name="alert" className="spacer" onChange={(e) => this.props.handleQuestion(e, this.props.index)}/>
            </div>
        );
    }
}

export default CreateQuiz;